
.Contant{
    .info_box{
        padding-top: 30px;

        .left_box{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            height: 400px;
            width: auto;
            padding: 30px;
            margin-bottom: 30px;
            .title{
                font-size: 24px;
                font-weight: bold;
            }
            .left{
                // display: flex;
                // align-items: center;
                // background: #f0f0f0;
                background: rgba(245, 245, 245, 0.9);
                padding: 30px;
                // min-height: 400px;
                // margin: 15px;
                .box{
                    // margin-left: 30px;
                    line-height: 30px;
                    // background:rgba(255, 255, 255,0.8);
                    
                }
            }
            
        }

        .right{

        }
    }
}
@media (min-width:992px) and (max-width:1199px){
    .left_box{
        margin-right:30px;
    }
}
@media (min-width:1200px){
    .left_box{
        margin-right:30px;
    }
}