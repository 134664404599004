@import '../../styles/theme.scss';

.Introduce{
    .info_box{
        padding-top: 30px;
        .img_box{
            padding: 30px;
            max-width:526px;
            // height:350px
        }
        .right{
            display: flex;
            align-items: center;
            padding: 30px;
            .title{
                font-size: 28px;
                font-weight: bold;
                color: $theme_color;
                margin-bottom: 30px;
            }
            .text{
                text-indent:35px;
                line-height: 40px;
    
            }
        }

    }

}
