.Plan{
    .bgimg{
        max-height: 350px;
        background-clip:content-box;
        // position: relative;
        .text{
            font-size: 28px;
            font-weight: bold;
            // position: absolute;
            // top: 0;
        }
    }
    .info_box{
        .title{
            font-size: 20px;
        }
        .title1{
            font-size: 20px;
            padding: 10px 0;
        }
        .box{
            margin-bottom: 20px;
        }
        .img{
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }
    }
}