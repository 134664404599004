@import '../../styles/theme.scss';

.Home{
    .carousel_img{
        max-height: 350px;
        color: #fff;
        // line-Height: 350px;
        text-Align: center;
        // background: $theme_color;
    }
    
    .info_box{
        // display: flex;
        padding: 30px;
        .col_box{
            display: flex;
            justify-content: center;
        }
        .box{
            background: #f0f0f0;
            text-align: center;
            margin: 15px;
            // width:200px;
            max-width: 405px;
            border-radius: 15px;
            .img_box{
                max-width: 405px;
                max-height: 280px;
                // margin: 150px auto 0;
                overflow: hidden;
                display: flex;
                justify-content: center;
                border-radius: 15px 15px 0 0;
                .img{
                    transition: all 0.6s ease 0s;
                    
                }
    
                .img:hover{
                    transform: scale(1.2);
                    /*transition: all 2s ease-in-out 0s;*/
                }
            }


            .text{
                font-weight: bold;
                font-size: 22px;
                padding: 18px;
            }
        }
        .box:hover{
            // -moz-box-shadow:0 0 10px #f0f0f0;
            // -webkit-box-shadow:0 0 10px #f0f0f0;
            box-shadow:0 0 15px #ccc;
            background: #fff;
            // border: 1px #f0f0f0 solid;
        }
    }
}
