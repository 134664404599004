.Product{
    // .info_box{
        // display: flex;
        min-height: 100px;
        .menu_box{
            height: 48px;
            // background-color: orange;
            position: sticky;
            position: -webkit-sticky;
            top: 85px;
            z-index: 1;
        }
        .left_box{
            background: #f5f4f4;
            padding: 30px 0;
            position: sticky;
            position: -webkit-sticky;
            top:100px;
            .group{
                // padding: 15px 0;
                .top{
                    padding: 10px 30px;
                }
                .ac{
                    background: #fff!important;
                }
                .group_text{
                    padding: 10px 30px;
                    list-style-type: none;
                    text-indent:2em;
                }

            }
        }
        .right_box{

        }
    // }
}
